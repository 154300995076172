<template>
  <v-toolbar dense class="flex-grow-0">
    <v-btn icon @click="$emit('click:back')">
      <v-icon>$back</v-icon>
    </v-btn>
    <v-toolbar-title class="font-weight-medium">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <dropdown-menu
      v-if="actions.length > 0"
      item-key-text="name"
      :items="actions"
      offset-y
      @input="handleInput"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>$more_vert</v-icon>
        </v-btn>
      </template>
    </dropdown-menu>
  </v-toolbar>
</template>

<script>
import { computed, onMounted, ref, toRefs } from '@vue/composition-api';

export default {
  name: 'ToolBar',
  props: {
    title: {
      type: String,
      default: ''
    },
    showHideLinesAction: {
      type: Boolean,
      default: false
    },
    showAnimateTrackAction: {
      type: Boolean,
      default: false
    },
    hideLines: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { showHideLinesAction, showAnimateTrackAction, hideLines } = toRefs(
      props
    );
    const checkedId = ref('day');
    const animateTrackAction = computed(() => {
      if (!showAnimateTrackAction.value) return [];
      return [
        {
          props: {
            id: 'animateTrack',
            title: 'Animate track',
            icon: '$animate_track'
          }
        }
      ];
    });
    const hideLineAction = computed(() => {
      if (!showHideLinesAction.value) return [];

      return [
        !hideLines.value
          ? {
              props: {
                id: 'hideLines',
                title: 'Hide Lines',
                icon: '$track'
              }
            }
          : {
              props: {
                id: 'hideLines',
                title: 'Show Lines',
                icon: '$track'
              }
            }
      ];
    });

    const actions = computed(() => [
      {
        type: 'DropdownMenuItemCheck',
        props: {
          id: 'day',
          title: 'Day',
          icon: '$calendar',
          checked: checkedId.value === 'day'
        }
      },
      {
        type: 'DropdownMenuItemCheck',
        props: {
          id: 'week',
          title: 'Week',
          icon: '$calendar',
          checked: checkedId.value === 'week'
        }
      },
      {
        type: 'DropdownMenuItemCheck',
        props: {
          id: 'month',
          title: 'Month',
          icon: '$calendar',
          checked: checkedId.value === 'month'
        }
      },
      {
        type: 'DropdownMenuItemDivider'
      },
      {
        type: 'DropdownMenuItemCheck',
        props: {
          id: 'customPeriod',
          title: 'Custom period',
          icon: '$calendar',
          checked: checkedId.value === 'customPeriod'
        }
      },
      {
        type: 'DropdownMenuItemDivider'
      },
      ...hideLineAction.value,
      ...animateTrackAction.value,
      {
        props: {
          id: 'download',
          title: 'Download history',
          icon: '$download'
        }
      }
    ]);

    const handleInput = action => {
      const { id } = action;
      checkedId.value = id;
      emit('click:action', id);
    };

    onMounted(() => {
      handleInput({ id: 'day' });
    });

    return {
      actions,
      handleInput
    };
  }
};
</script>

<style></style>
